<template>
	<div>
		<component
			:is="block._type"
			v-for="(block, index) in blocks"
			:key="index"
			:block-data="block"
		/>
	</div>
</template>

<script>
// import all the components individually from the /components/new folder
import TextBlock from "../TextBlock.vue";
import Bio from "../Bio.vue";
import TwoColumnContent from "../TwoColumnContent.vue";
import Hero from "../Hero.vue";
import WhatWeDo from "../WhatWeDo.vue";
import WhatWeDoButton from "../WhatWeDoButton.vue";
import ProgramContent from "../ProgramContent.vue";
import HeroHome from "../HeroHome.vue";
import StoryContent from "../StoryContent.vue";
import GetInTouch from "../GetInTouch.vue";
import AlexBlock from "../AlexBlock.vue";
import TalkSeries from "../TalkSeries.vue";
import TwoColumnContentBook from "../TwoColumnContentBook.vue";
import BookTestimonialCarousel from "../BookTestimonialCarousel.vue";
import ConnectWithUs from "../ConnectWithUs.vue";

export default {
	name: "Blocks",
	props: {
		blocks: {
			type: Array,
			required: true
		}
	},
	components: {
		TextBlock,
		Bio,
		TwoColumnContent,
		WhatWeDoButton,
		Hero,
		ProgramContent,
		WhatWeDo,
		HeroHome,
		StoryContent,
		GetInTouch,
		AlexBlock,
		TalkSeries,
		TwoColumnContentBook,
		BookTestimonialCarousel,
		ConnectWithUs
	}
};
</script>
