<template>
    <div id="mc_embed_shell">
      <div id="mc_embed_signup">
        <form
          action="https://relationship-ready.us18.list-manage.com/subscribe/post?u=c933192b57fc24de02186fcf7&amp;id=dffdd1898d&amp;v_id=5935&amp;f_id=007560e7f0"
          method="post"
          id="mc-embedded-subscribe-form"
          name="mc-embedded-subscribe-form"
          class="validate"
          target="_blank"
        >
          <div id="mc_embed_signup_scroll">
            <div>
              <h1>Stay connected</h1>
            </div>
            <div class="indicates-required">
              <span class="asterisk">*</span> indicates required
            </div>
            <div class="mc-field-group">
              <label for="mce-EMAIL">Email Address <span class="asterisk">*</span></label>
              <input
                type="email"
                name="EMAIL"
                class="required email"
                id="mce-EMAIL"
                required
                value=""
              />
            </div>
            <div id="mergeRow-gdpr" class="mergeRow gdpr-mergeRow content__gdprBlock mc-field-group">
              <div class="content__gdpr">
                <label>Marketing Permissions</label>
                <p>The information you provide us is a way for you to stay connected with Relationship Ready.</p>
                <fieldset class="mc_fieldset gdprRequired mc-field-group" name="interestgroup_field">
                  <label class="subfield" for="gdpr_12701">
                    <input type="checkbox" id="gdpr_12701" name="gdpr[12701]" class="gdpr" value="Y" />
                    <span>Email</span>
                  </label>
                </fieldset>
              </div>
              <div class="content__gdprLegal">
                <p>
                  We use Mailchimp as our marketing platform. By clicking below to subscribe, you
                  acknowledge that your information will be transferred to Mailchimp for processing.
                  <a href="https://mailchimp.com/legal/terms">Learn more</a> about Mailchimp's privacy
                  practices.
                </p>
              </div>
            </div>
            <div id="mce-responses" class="clear foot">
              <div class="response" id="mce-error-response" style="display: none;"></div>
              <div class="response" id="mce-success-response" style="display: none;"></div>
            </div>
            <div aria-hidden="true" style="position: absolute; left: -5000px;">
              <input type="text" name="b_c933192b57fc24de02186fcf7_dffdd1898d" tabindex="-1" value="" />
            </div>
            <div class="optionalParent">
              <div class="clear foot">
                <input
                  type="submit"
                  name="subscribe"
                  id="mc-embedded-subscribe"
                  class="button"
                  value="Subscribe"
                />
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </template>

  <script>
  export default {
    name: "MailchimpForm",
    mounted() {
        const script = document.createElement("script");
        script.src = "//s3.amazonaws.com/downloads.mailchimp.com/js/mc-validate.js";
        script.async = true;
        document.body.appendChild(script);

        const link = document.createElement("link");
        link.href = "//cdn-images.mailchimp.com/embedcode/classic-061523.css";
        link.rel = "stylesheet";
        link.type = "text/css";
        document.head.appendChild(link);
    },
  };
  </script>

  <style>
#mc_embed_signup div.mce_inline_error {
    background: #efebe6 !important;
}</style>
  <style scoped>
  #mc_embed_shell {
    background: #efebe6;
    padding: 9vw 0 0;
  }
  #mc_embed_signup {
    background: #efebe6;
    clear: left;
    font: 1.3vw 'DM Sans', sans-serif;
    width: 100%;
    max-width: 50vw;
    margin-left: auto;
    margin-right: auto;
  }
  #mc_embed_signup .indicates-required {
    text-align: right;
    font-size: 1.1vw;
    margin-right: 4%;
}

#mc_embed_signup_scroll h1 {
  font-size: 2.5vw;
  margin-top: 0;
  font-family: BonVivant;
}

  #mc_embed_signup form {
    margin: 0;
  }

  #mc-embedded-subscribe-form input[type="checkbox"] {
    display: inline;
    width: auto;
    margin-right: 10px;
  }

  #mc_embed_signup .mc-field-group input[type="email"] {
    position: relative;
    background: none;
    background-color: transparent;
    border: none;
    border-bottom: .2vw solid #d4c09e;
    outline: none;
    font-size: 1.4vw;
  }

  #mergeRow-gdpr {
    margin-top: 20px;
  }

  #mergeRow-gdpr fieldset label {
    font-weight: normal;
  }

  #mc-embedded-subscribe-form .mc_fieldset {
    border: none;
    min-height: 0px;
    padding-bottom: 0px;
  }

  #mc_embed_signup .button {
    position: relative;
    left: 0;
    margin-top: 2.5vw;
    width: 18vw;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 700;
    border-radius: 0;
    height: 4.5vw;
    display: block;
    color: #544a3f;
    top: -2vw;
    text-transform: uppercase;
    font-size: 1vw;
    background: none;
    border: .2vw solid #d4c09e;
    cursor: pointer;
  }
  #mc_embed_signup .button:hover {
    background-color: transparent;
  }
  #mc_embed_signup_scroll a {
    color: #544a3f;
    font-weight: 700;
  }

  @media (max-aspect-ratio: 200 / 200) {
    #mc_embed_shell {
      padding: 25vw 0 0;
  }

    #mc_embed_signup {
      max-width: 80%;
    }
    #mc_embed_signup_scroll h1 {
  font-size:7vw;
}
  #mc_embed_signup, #mc_embed_signup .button, #mc_embed_signup .mc-field-group input,
  #mc_embed_signup .mc-field-group input[type="email"] {
    font-size: 3.2vw;
}
  #mc_embed_signup .indicates-required {
    font-size: 2vw;
}
#mc_embed_signup .button {
        width: 50vw;
        height: 12.5vw;
        border: .5vw solid #d4c09e;
        margin-top: 3vw;
}
}
  </style>
